.featured-article {
  position: relative;
  width: 100%;
  /* max-width: 800px; */
  height: 350px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

/* Gradient Overlay */
.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background: linear-gradient(to top, rgba(0, 0, 0, .9), rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));
}

.featured-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-controls {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(255, 255, 255, 0.8); */
  border-radius: 8px;
  padding: 6px 12px;
}

.icon-button {
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4) !important;
}

.icon-button:hover {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5) !important;
}


.draft-label {
  background-color: yellow;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  width: 100px;
}

.edit-delete-icons {
  display: flex;
  gap: 8px;
}

.article-info {
  position: absolute;
  bottom: 12px;
  left: 12px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  color: white;
  padding: 12px;
  border-radius: 8px;
}

.nav-buttons {
  position: absolute;
  bottom: 12px;
  right: 12px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  color: white;
  padding: 12px;
  border-radius: 8px;
}


.nav-button {
  /* position: absolute; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  color: white;
  background-color: white;
}

.nav-button.left {
  left: 8px;
}

.nav-button.right {
  right: 8px;
}


.secondary-articles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%; /* Ensures the parent container is fully utilized */
}

.secondary-article-card {
  position: relative;
  flex-grow: 1; /* Distributes available space evenly */
  min-height: 100px; /* Prevents cards from becoming too small */
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
}


/* .secondary-article-card:hover {
  transform: scale(1.03);
} */

/* Semi-transparent overlay to enhance text readability */
.image-overlay {
  position: absolute;
  /* inset: 0; */
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, .9), rgba(0, 0, 0, .6), rgba(0, 0, 0, 0));
}

.secondary-article-content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  color: white;
  padding: 6px;
  border-radius: 8px;
}
.secondary-article-title {
  font-size: 1rem;
  font-weight: bold;
  /* background-color: rgba(0, 0, 0, 0.6); */
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  text-align: center;
}

.tertiary-articles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.tertiary-article-card {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.tertiary-article-image {
  width: 150px;
  height: 100px;
  background-size: cover;
  background-position: center;
}

.tertiary-article-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  position: relative;
}

.tertiary-article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tertiary-article-title {
  font-size: 1rem;
  font-weight: bold;
}

.draft-label {
  background-color: yellow;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}

.tertiary-article-description {
  margin-top: 4px;
  font-size: 0.9rem;
  color: #555;
}

.tertiary-article-meta {
  font-size: 0.8rem;
  color: #777;
  margin-top: 4px;
}

.tertiary-article-actions {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 6px;
}

